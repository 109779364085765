import * as React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const IndexPage = () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    document.querySelector('button[type="submit"]').disabled = true;
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };
  return (
    <Layout>
      <SEO title="Book a trip with E1 Transit" />
      <main>
        <div
          className="wrapper-body"
          style={{ backgroundColor: '#fff' }}
          id="why-use-e1"
        >
          <section
            id="intro"
            style={{ height: '450px', backgroundImage: `url(${HeroImage})` }}
          >
            <div className="container">
              <div
                className="col-md-12"
                style={{ paddingTop: '120px', paddingBottom: '100px' }}
              >
                <h1>Book a trip with E1 Transit</h1>
              </div>
            </div>
          </section>

          <div
            className="padding-topbottom-50 container"
            style={{ color: '#a2a2a2' }}
          >
            <div className="col-md-offset-2 col-md-8">
              <p className="margin-bottom-50">
                Would you like to book a trip with E1 Transit? E1 Transit is
                currently operating on a limited service schedule. To reach us,
                use the form below:
                <br />
                <br />
                Please let us know the date and time of your trip, number of
                passengers and pickup/dropoff addresses.
              </p>
              <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className="contactForm form-horizontal"
              >
                <div className="form-group">
                  <label
                    htmlFor="contactName"
                    className="col-sm-2 control-label"
                  >
                    Your Name*
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      required
                      name="name"
                      placeholder="Your Name"
                      id="contactName"
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="contactEmail"
                    className="col-sm-2 control-label"
                  >
                    Email*
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="email"
                      required
                      name="email"
                      placeholder="Your E-Mail Address"
                      id="contactEmail"
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="contactPhone"
                    className="col-sm-2 control-label"
                  >
                    Phone
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Your Phone Number"
                      id="contactPhone"
                      className="form-control input-lg"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="contactEmail"
                    className="col-sm-2 control-label"
                  >
                    Message*
                  </label>
                  <div className="col-sm-10">
                    <textarea
                      type="text"
                      required
                      name="message"
                      placeholder="Your Message"
                      id="contactComments"
                      className="form-control input-lg form-comment"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div
                  className="col-sm-offset-5 col-sm-8"
                  style={{ paddingLeft: '5px' }}
                >
                  <button
                    id="contactSubmit"
                    className="btn btn-lg btn-success form-submit"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>

                <h2
                  className="padding-bottom-30 confirmform"
                  style={{ display: 'none' }}
                >
                  Thanks for contacting us, we will be in touch soon!
                </h2>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
